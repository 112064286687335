import React from 'react';
import { Helmet } from 'react-helmet';
import Favicon from '../../static/images/favicon.ico';
import SchemaLdJSON from '../../static/files/Schema.json';
import PropTypes from 'prop-types';

export default function SEO({ title, description }) {
  function includeLdJSON() {
    return (
      <script type="application/ld+json">
        {JSON.stringify(SchemaLdJSON)}
      </script>
    );
  }

  return (
    <Helmet htmlAttributes={{ lang: 'pt-br' }}>
      <meta charSet='utf-8' />
      <meta name="google-site-verification" content={process.env.GATSBY_GOOGLE_SC_TAG} />
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta
        name="description"
        content={description} />
      <link rel="shortcut icon" href="data:image/x-icon;," type="image/x-icon" />
      <link rel="icon" href={Favicon} />
      {includeLdJSON}
    </Helmet>
  );
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

SEO.defaultProps = {
  title: 'Pesquisa de CNAE - Simples Nacional',
  description: 'Encontre o CNAE adequado para sua empresa e conte com nossa equipe para abrir sua empresa. Somos uma contabilidade digital feita por pessoas e para pessoas.',
};
